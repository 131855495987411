import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"

import { FaTwitter } from 'react-icons/fa'
import { FaLinkedin } from 'react-icons/fa'

const IndexPage = () => (
  <Layout>
    <SEO
      title={`Home`}
      description={`Matt Langeman is a web developer and consultant living in Owen Sound, Ontario, Canada.`}
    />

<div className={`flex flex-col items-center w-full`}>
      <article className={`w-full md:mx-w-4xl lg:max-w-4xl xl:max-w-4xl`}>
        <div className={`bg-white shadow py-6 lg:p-10 mb-2 md:mb-6`}>
          <div className={`flex flex-col content-center lg:flex-row pb-8 md:pt-4`}>
              <div className={`mx-auto`}>
                <Image filename="MattHeadShot.jpg" className={`rounded-full shadow-lg w-32 lg:w-40`} alt="Me" />
                <div className={`w-full text-center mx-auto mt-2`}>
                  <a href="https://twitter.com/mattlangeman" className={`icon`} target="_blank" rel="noreferrer"><FaTwitter /></a>
                  <a href="https://www.linkedin.com/in/mattlangeman/" className={`icon`} target="_blank" rel="noreferrer"><FaLinkedin /></a>
                </div>
              </div>
              <div className={`mx-6 markdown`}>
                <h1 className={`text-xl font-bold mt-4 md:mt-0 mb-2 leading-tight`}>Hello World</h1>
                <p className={`mb-6`}>
                  I'm Matt Langeman. I live in <a href="https://www.google.com/maps/place/Owen+Sound,+ON/" target="_blank" rel="noopener noreferrer">Owen Sound, Ontario</a> with
                  my wife and three kids. I have worked professionally in software development and consulting since 2001.
                  Recently I have been getting more into data analysis, visualization, and dashboards.
                </p>
                <h1 className={`text-l font-bold mt-4 md:mt-0 mb-2 leading-tight`}>Work</h1>
                <p className={`mb-6`}>
                    I am a software and data consultant with a focus on data-driven web-applications.
                    I operate independently as a solo developer/consultant under the business <a href="http://junctionstation.ca/" target="_blank" rel="noopener noreferrer">Junction Station</a>.
                </p>
                <p className={`mb-6`}>
                    Since late 2016, I have primarily been working with <a href="https://www.dai.com/our-work/solutions/sustainable-business" target="_blank" rel="noopener noreferrer">DAI Sustainable Business Group</a>.
                    I've led the design, implementation, and development of <a href="https://www.dai.com/our-work/local-content-optimization-model" target="_blank" rel="noopener noreferrer">their web-based forecasting</a> tool
                    used to support data-driven decisions related to local content strategy. I also
                    provide technical advice and management for their other software products, and I work
                    with leadership to define the role of software products within the larger team strategy.
                </p>
                <p className={`mb-6`}>
                  Preferred Tech Stack: Python/Django, Vue or React, Tailwind CSS, Jupyter, Highcharts, D3
                </p>
                <h1 className={`text-l font-bold mt-4 md:mt-0 mb-2 leading-tight`}>Life</h1>
                <p className={`mb-6`}>
                  Family is a big part of my life. I have three kids (born in 2013, 2014, 2015) and
                  spent several years as a stay-at-home dad. I am an <a href="https://www.mennonitemission.net/Downloads/DL.MissioDei18.E.pdf" target="_blank" rel="noopener noreferrer">Anabaptist Christian</a> and
                  attend the <a href="https://www.facebook.com/tmhowensound/" target="_blank" rel="noopener noreferrer">Owen Sound site </a>
                  of <a href="https://www.themeetinghouse.com/" target="_blank" rel="noopener noreferrer">The Meeting House</a>.
                </p>
                <p className={`mb-6`}>
                  I enjoying hiking <a href="https://www.owensoundtourism.ca/en/explore/Trails.aspx" target="_blank" rel="noopener noreferrer">the Bruce Trail and other trails near my home</a>. A recent injury has stopped me from playing with
                  the <a href="https://owensoundultimate.com/" target="_blank" rel="noopener noreferrer">Ultimate Frisbee crew here in Owen Sound</a>,
                  but I hope to get back out there soon.
                </p>
              </div>
          </div>
        </div>
      </article>
    </div>
  </Layout>
)

export default IndexPage